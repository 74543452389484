import React, { createContext, useState, useContext } from 'react';
import axios from 'axios';


// Create Context
export const ChatContext = createContext();

// Custom hook to use the chat context
export const useChat = () => useContext(ChatContext);

// Provider Component
export const ChatProvider = ({ children }) => {
  const [context_systemPrompt, context_setSystemPrompt] = useState("");
  const [context_userPrompt, context_setUserPrompt] = useState("");
  const [context_showUser, context_setShowUser] = useState(true);
  const [context_showAgent, context_setShowAgent] = useState(true);
  const [context_chatLog, context_setChatLog] = useState({});
  const [context_questionId, context_setQuestionId] = useState("");
  const [context_siteLocation, context_setSiteLocation] = useState("");
  const [context_ConvoTop, context_setConvoTop] = useState("");
  const [context_chatId, context_setChatId] = useState("");
  const [context_context, context_setContext] = useState("");
  const [context_listing_id, context_set_listing_id] = useState("");  // New state for listing ID
  const [context_ContextQuestionOrigin, context_setContextQuestionOrigin] = useState("");

	
  // Function to log user interactions to the Flask API
  const context_logUserInteraction = async (question, answer, questionSource = 'system') => {
    const logData = {
      listing_id: context_listing_id, // Use the listing ID from the context
      question: question,             // Question presented to the user
      system_prompt: context_systemPrompt, // System prompt from the context
      question_source: questionSource,     // Source of the question (default to 'system')
      answer: answer                  // User's response
    };

    const baseUrl = (() => {
      const hostname = window.location.hostname;
      if (hostname === 'localhost') {
        return 'http://localhost:5000/api';
      } else if (hostname === 'www.aigentTechnologies.com') {
        return 'https://www.aigentTechnologies.com/api';
      } else if (hostname === 'www.openhouseaigent.com') {
        return 'https://www.openhouseaigent.com/api';
      } else {
        return 'https://hbb-zzz.azurewebsites.net/api';
      }
    })();
  
    const convoTop = context_ConvoTop || 'default';

    try {
      const response = await axios.post(`${baseUrl}/log-user-interaction`, logData); // Assuming this is the Flask route
//      console.log('User interaction logged successfully:', response.data);
    } catch (error) {
      console.error('Error logging user interaction:', error);
    }
  };	
	
  // Provide the state and functions to the context consumers
  return (
    <ChatContext.Provider value={{
      context_systemPrompt,
      context_setSystemPrompt,
      context_userPrompt,
      context_setUserPrompt,
      context_showUser,
      context_setShowUser,
      context_showAgent,
      context_setShowAgent,
      context_chatLog,
      context_setChatLog,
      context_siteLocation,
      context_setSiteLocation,
      context_ConvoTop,
      context_setConvoTop,
      context_questionId,
      context_setQuestionId,
      context_chatId,
      context_setChatId,
      context_context, 
      context_setContext,
	  context_ContextQuestionOrigin,  // for logging
	  context_setContextQuestionOrigin,
      context_listing_id,          // Provide listing_id
      context_set_listing_id,       // Provide setter for listing_id
	  context_logUserInteraction
    }}>
      {children}
    </ChatContext.Provider>
  );
};
