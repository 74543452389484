import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from '../styles/Aigent_2.module.css';
import { useSwipeable } from 'react-swipeable';
import { useChat } from '../contexts/ChatContext';
import axios from 'axios';

const Aigent_2 = ({ collapseCarousel, showFull = true, isModal = false, showLabels = true, maxHeight = "300px"}) => {
  const minHeight = maxHeight;
  const [isLoading, setIsLoading] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false); // Track if streaming is cancelled
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [visibleQuestionCount, setVisibleQuestionCount] = useState(3);	
  const { 
    context_chatLog, 
    context_addMessageToChatLog, 
    context_systemPrompt, 
    context_setSystemPrompt, 
    context_userPrompt, 
    context_setUserPrompt, 
    context_chatId, 
    context_setChatId, 
    context_siteLocation, 
    context_setSiteLocation, 
    context_ConvoTop, 
    context_setConvoTop, 
    context_questionId, 
    context_setQuestionId, 
    context_showUser, 
    context_setShowUser,  
    context_showAgent, 
    context_setShowAgent, 
    context_context, 
    context_setContext,    
    context_ContextQuestionOrigin,  // for logging
	context_setContextQuestionOrigin,
    context_listing_id,          // Provide listing_id
    context_set_listing_id,       // Provide setter for listing_id
	context_logUserInteraction	  
  } = useChat();
  
  useEffect(() => {
//	console.log('SYSTEM PROMPT', context_systemPrompt);
    if (context_systemPrompt) {
      handleSubmit(null, context_systemPrompt, context_userPrompt, context_questionId);

      // Reset context variables (except chatLog)
      context_setSystemPrompt("");
      context_setUserPrompt("");
      context_setQuestionId("");
      context_setShowUser(true);
      context_setShowAgent(true);
	  context_setContext("");
      context_setConvoTop("");
    }
  }, [context_systemPrompt, context_userPrompt]);

  const chatLogRef = useRef(null);
  const controllerRef = useRef(null);
	
  // SCROLLING CODE
  // 1. Track if auto-scroll is enabled and if the scroll button should be shown
  const [isAutoScrollEnabled, setIsAutoScrollEnabled] = useState(false); // Control auto-scroll
  const [showScrollButton, setShowScrollButton] = useState(false); // Show when content exceeds the visible area

  // 2. Detect user scrolling and content overflow to show the scroll button
  useEffect(() => {
    const chatLog = chatLogRef.current;

    const handleScroll = () => {
      if (chatLog) {
		setShowScrollButton(false);
        const isAtBottom = chatLog.scrollHeight - chatLog.scrollTop === chatLog.clientHeight;
        setIsAutoScrollEnabled(false); 
      }
    };

    if (chatLog) {
      chatLog.addEventListener('scroll', handleScroll);
      chatLog.addEventListener('touchmove', handleScroll); 
    }

    return () => {
      if (chatLog) {
        chatLog.removeEventListener('scroll', handleScroll);
        chatLog.removeEventListener('touchmove', handleScroll);
      }
    };
  }, [chatLogRef]);

	
	
  // 3. Check if content exceeds the visible area and show the scroll button
  useEffect(() => {
    const chatLog = chatLogRef.current;

    if (chatLog) {
      const isScrollable = chatLog.scrollHeight > chatLog.clientHeight;
      setShowScrollButton(isScrollable); 
    }
  }, [messages]); 
	
	
	

  // 5. Handle when the user clicks the scroll button to enable auto-scroll and hide the button immediately
  const handleScrollToBottom = () => {
    // Hide the button immediately when clicked, regardless of whether the chat log reaches the bottom or not
    setShowScrollButton(false);
    // Enable auto-scroll
    setIsAutoScrollEnabled(true);

    // Scroll to the bottom (auto-scroll will handle the rest for new messages)
    if (chatLogRef.current) {
      chatLogRef.current.scrollTo({
        top: chatLogRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  };
	
  // END SCROLLING CODE

  // QUICK QUESTIONS
  // Limit the number of questions visible in the carousel
//  useEffect(() => {
//    if (questions.length > 0) {
//      setVisibleQuestionCount(questions.length); 
//    }
//  }, [questions]);
	
  // Quick question carousel state
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);

  // State for questions and system prompts
  const [questions, setQuestions] = useState([]); // Will hold the fetched questions
  const [questionPrompts, setQuestionPrompts] = useState([]); // Will hold the system prompts
  const [aIgentQuickQuestion, setAIgentQuickQuestion] = useState(null); // State to hold OWQQ question
	

  useEffect(() => {
    fetchQuestions();
  }, [context_siteLocation, context_ConvoTop]); // Watch for changes in `context_siteLocation`

  // Function to fetch questions based on `SITE_LOCATION` and `CONVOTOP`
  const fetchQuestions = async () => {
    try {
      const baseUrl = (() => {
        const hostname = window.location.hostname;
        if (hostname === 'localhost') {
          return 'http://localhost:5000/api';
        } else if (hostname === 'www.aigentTechnologies.com') {
          return 'https://www.aigentTechnologies.com/api';
        } else if (hostname === 'www.openhouseaigent.com') {
          return 'https://www.openhouseaigent.com/api';
        } else {
          return 'https://hbb-zzz.azurewebsites.net/api';
        }
      })();

      const convoTop = context_ConvoTop || 'default';
      
      // Fetch `SITE_LOCATION` questions, continue with empty response if not found
      const siteLocationResponse = await axios.get(`${baseUrl}/get_site_location_questions`, {
        params: { SITE_LOCATION: context_siteLocation }
      }).catch(error => {
        console.error("Error fetching SITE_LOCATION questions:", error.response || error.message);
        return { data: [] }; // Return empty data if an error occurs
      });

      // If no `SITE_LOCATION` questions found, move to `CONVOTOP` only
      let combinedData = siteLocationResponse.data.map(q => ({ ...q, source: 'SITE_LOCATION' }));
	  console.log('COMBINED DATA 1', combinedData);

      // Fetch `CONVOTOP` questions and add to the combined data
      const convotopResponse = await axios.get(`${baseUrl}/get_convotop_questions`, {
        params: { CONVOTOP: convoTop }
      }).catch(error => {
        console.error("Error fetching CONVOTOP questions:", error.response || error.message);
        return { data: [] }; // Return empty data if an error occurs
      });

      combinedData = [...combinedData, ...convotopResponse.data.map(q => ({ ...q, source: 'CONVOTOP' }))];
	  console.log('COMBINED DATA 2', combinedData);

      // **If OWQQ was found earlier, append it**
      if (aIgentQuickQuestion) {
		// Ensure `aIgentQuickQuestion` is an array of question objects
		const normalizedOWQQQuestions = Array.isArray(aIgentQuickQuestion)
		  ? aIgentQuickQuestion.map((question, index) => ({
			  question_id: question.question_id || `owqq-${index}`, // Generate an ID if missing
			  quick_question: question.quick_question || question,
			  quick_question_system_prompt: question.quick_question_system_prompt || '',
			  qucik_question_order: question.qucik_question_order || index,
			  source: question.source || 'OWQQ'
			}))
		  : [aIgentQuickQuestion]; // Wrap in array if it's a single object		  
		combinedData = [...combinedData, ...normalizedOWQQQuestions];
//        combinedData.push(aIgentQuickQuestion);
      }
	  	  console.log('COMBINED DATA 3', combinedData);

		
      // If questions are found, set the state
      if (combinedData.length > 0) {
        const questionList = combinedData.map(q => q.quick_question);
        const questionPromptList = combinedData.map(q => q.quick_question_system_prompt);

        setQuestions(combinedData.flat());
        setQuestionPrompts(questionPromptList);
        setVisibleQuestionCount(combinedData.length);
      } else {
        console.log('No questions found for either CONVOTOP or SITE_LOCATION.');
      }

    } catch (error) {
      console.error('Error fetching questions:', error.response || error.message);
    }
  };
	
	
  const handleNext = () => {
    setVisibleStartIndex((prevIndex) => (prevIndex + 1) % questions.length);
  };

  const handlePrevious = () => {
    setVisibleStartIndex((prevIndex) => (prevIndex === 0 ? questions.length - 1 : prevIndex - 1));
  };
	
	

  const getVisibleQuestions = () => {
    const endSlice = questions.slice(visibleStartIndex, visibleStartIndex + visibleQuestionCount);
	//	console.log("Visible questions for carousel:", endSlice, questions	);	  
//	  console.log("visibleStartIndex:", visibleStartIndex, "visibleQuestionCount:", visibleQuestionCount, "Questions length:", questions.length, "questions", questions);
	  
    const remainingItems = visibleStartIndex + visibleQuestionCount > questions.length 
      ? questions.slice(0, (visibleStartIndex + visibleQuestionCount) % questions.length) 
      : [];
    return [...endSlice, ...remainingItems];
  };	
	

	
	
//  const handleQuestionClick = (question) => {
//    setUserInput(question);
//  };	
	
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });
  // END QUICK QUESTIONS
  // UseEffect to watch for updates to questions
  useEffect(() => {
    if (aIgentQuickQuestion) {
      setQuestions((prevQuestions) => [...prevQuestions, aIgentQuickQuestion]);
    }
  }, [aIgentQuickQuestion]);	
	
  const handleQuestionClick = (question, index) => {
    // Add the user question to the chat log
//    setMessages(prevMessages => [
//      ...prevMessages, 
//      { user: "User", text: question }
//    ]);

    // Set the user input with the clicked question
    setUserInput(question);

    // Trigger handleSubmit with the corresponding system prompt
    const selectedSystemPrompt = questionPrompts[index];
    handleSubmit(null, selectedSystemPrompt, question);
  };	
	
	

  const bufferRef = useRef('');	
  const handleSubmit = async (event, prompt = null, input = userInput, questionID = null) => {
    if (event) event.preventDefault();

    const question = input || userInput;
    let effectivePrompt = prompt || userInput;
    const qID = questionID || "";
	const questionSource = context_ContextQuestionOrigin || 'NA';

    setIsLoading(true);
    setIsCancelled(false); // Reset cancel status
	  
	  
    // Check if `context_context` exists, and update the prompt accordingly
    if (context_context) {
//      effectivePrompt = 'The user is currently viewing an image: ' + context_context + '. Here is the user\'s prompt: ' + effectivePrompt;
    }
	  


    if (!question.trim()) {
      setMessages(prevMessages => [...prevMessages, { user: "AIgent", text: "Please enter a question." }]);
      setIsLoading(false);
      return;
    }

    setMessages(prevMessages => [...prevMessages, { user: "User", text: question }]);
    setUserInput("");

    // Initialize AbortController to allow canceling
    const controller = new AbortController();
    controllerRef.current = controller; // Store reference to the controller

    try {
      const payload = {
        user_question: question.trim(),
        system_prompt: effectivePrompt,
        question_id: qID,
        question_origin: questionSource,
      };

      const response = await fetch("/api/ask_stream", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        signal: controller.signal // Attach the abort controller signal
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
//      let buffer = '';

      const processText = async ({ done, value }) => {
        try {
          if (done || isCancelled) {
            setIsLoading(false);
            if (bufferRef.current.trim()) {

            // Use extractJsonData to extract convotop, suggested questions, and binary responses
            const jsonData = extractJsonData(bufferRef.current);

            if (jsonData) {
			  console.log('JSON Found', jsonData);
              const { convotop, suggestedQuestions } = jsonData;

              // Update context and state with extracted data
              context_setConvoTop(convotop);
				
              // Update AIgentQuickQuestion with the list of suggested questions
			  setAIgentQuickQuestion(
				suggestedQuestions.map((question, index) => {
//				  console.log(`Mapping question ${index + 1}:`, question); 
				  return {
				     quick_question: question,
					 source: 'OWQQ'
				  };
				})
			  );


            }

            // Clean the buffer by removing JSON
            let cleanedBuffer = bufferRef.current.replace(/```json\s*([\s\S]*?)\s*```|\{[\s\S]*\}$/g, '').trim();
            cleanedBuffer = cleanedBuffer.replace(/[\[\]]/g, '').trim();
//            console.log("Final cleaned buffer:", cleanedBuffer);

            // Update the message with the cleaned buffer
			setMessages(prevMessages => {
                const lastMessage = prevMessages[prevMessages.length - 1];
                if (lastMessage.user === "AIgent") {
                  // Instead of appending, we now replace the entire message text with the cleaned buffer
                  return [...prevMessages.slice(0, -1), { ...lastMessage, text: cleanedBuffer }];
                } else {
                  return [...prevMessages, { user: "AIgent", text: cleanedBuffer }];
                }
              });				
				
			  if (!isModal) {
  			    fetchQuestions();
			  }

              bufferRef.current = '';  // Clear the buffer after processing
            }
            return;
          }			
			
          const chunk = decoder.decode(value, { stream: true });
          bufferRef.current += chunk; 

          setMessages(prevMessages => {
            const lastMessage = prevMessages[prevMessages.length - 1];
            if (lastMessage.user === "AIgent") {
              return [...prevMessages.slice(0, -1), { ...lastMessage, text: lastMessage.text + chunk }];
            } else {
              return [...prevMessages, { user: "AIgent", text: chunk }];
            }
          });

          return reader.read().then(processText);
        } catch (err) {
          if (err.name === 'AbortError') {
//            console.log("Stream has been aborted");
            return;
          } else {
            console.error("Error during reading stream: ", err);
            throw err;  // Re-throw other errors
          }
        }
      };

      reader.read().then(processText);

    } catch (error) {
      if (error.name === 'AbortError') {
//        console.log("Fetch request was aborted");
      } else {
        console.error("Error fetching data: ", error);
        setMessages(prevMessages => [...prevMessages, { user: "AIgent", text: "Failed to connect to the server." }]);
      }

      setIsLoading(false);
    }
  };
	
	const extractJsonData = (message) => {
	  // Match the JSON block in the message
	  const jsonMatch = message.match(/```json\s*([\s\S]*?)\s*```|\{[\s\S]*\}$/);
	  console.log('jsonMatch', jsonMatch, 'jsonMatch[1]', jsonMatch[1]);
		
	  if (jsonMatch && jsonMatch[1]) {
		try {
		  // Parse the matched JSON string
		  const jsonData = JSON.parse(jsonMatch[1]);

		  // Extract the convotop, defaulting to "default" if not found
		  const convotop = jsonData.convotop || '[CONVOTOP: default]';

		  // Extract suggested questions (equivalent to OWQQ)
		  let suggestedQuestions = jsonData.suggested_questions || [];
		  console.log('in extractJsonData', suggestedQuestions);

		  // Check if binary_responses is "yes" and add "Yes" and "No" options
		  if (jsonData.binary_responses && jsonData.binary_responses.toLowerCase() === "yes") {
			suggestedQuestions.push("Yes", "No");
		  }

		  console.log("Extracted convotop:", convotop);
		  console.log("Final suggested questions:", suggestedQuestions);
		  console.log("Binary responses added if applicable:", jsonData.binary_responses);

		  // Use this data in your application as needed
		  context_setConvoTop(convotop);  // Update conversation topic in context

			
		  setAIgentQuickQuestion(
            suggestedQuestions.map((question, index) => {
              console.log(`	Mapping question ${index + 1}:`, question); // Log each question and its index
              return {
              quick_question: question,
              source: 'OWQQ'
              };
            })
          );			

		  // Return or further process the data as needed
		  return { convotop, suggestedQuestions };

		} catch (error) {
		  console.error("Failed to parse JSON:", error);
		}
	  } else {
		console.log("No JSON structure found in the message.");
	  }

	  return null;  // Return null if no JSON found
	};
  const removeTrailingBracket = (message) => {
    // Check if the message ends with a "]" and remove it if it does
    if (message.endsWith(']')) {
      return message.slice(0, -1).trim(); // Remove the last character and trim any extra spaces
    }
    return message;
  };	
	
	
  const cancelResponse = async () => {
    setIsCancelled(true); // Set cancel status
    setIsLoading(false);  // Stop the loading state
    if (controllerRef.current) {
      controllerRef.current.abort(); // Abort the fetch request
//      console.log("Fetch request aborted");
    }
  };

  return (
    <div 
      className={`${styles.AigentContainer} ${isModal ? styles.AigentContainerModal : ''}`} 
	>
      {/* Add Cancel Button */}
      <div 
        className={`${styles.AigentchatbotContainer} ${isModal ? styles.AigentchatbotContainerModal : ''}`} 
      >	  
	  
        <div 
          className={`${styles.AigentchatLog} ${isModal ? styles.AigentchatLogModal : ''}`} 
          ref={chatLogRef}
 		  style={{ maxHeight }} 
 		  style={{ minHeight }} 
        >	  
          {messages.map((message, index) => (
            <div key={index} className={styles.AigentmessageContainer}>
              {/* Only display the user label if showLabels is true */}
              {showLabels && (
                <strong>{message.user}:</strong>
              )}

              {/* Conditionally render the message text: 
                  Show the text unless showLabels is false and message.user is "User" */}
              {!(showLabels === false && message.user === "User") && (
                <ReactMarkdown>{message.text}</ReactMarkdown>
              )}
            </div>
          ))}
          {/* Scroll Button */}
          <div className={styles.AigentscrollButtonContainer}>
            <button onClick={handleScrollToBottom} className={styles.AigentscrollButton} style={{ display: showScrollButton ? 'block' : 'none' }}>
              v
            </button>
          </div>

        </div>


        {/* Only show the below sections if `showFull` is true */}
        {showFull && (
          <>
			{/* Quick Question Carousel */}
			<div className={styles.AigentquickQuestionContainer}>
			  <div className={styles.AigentarrowContainer} onClick={handlePrevious}>←</div>

			  <div className={styles.AigentquestionCarousel}>
			    {getVisibleQuestions().map((question, index) => (
				  <div
					key={index}
					className={`${styles.AigentquestionItem} ${index === 0 ? styles.AigenthighlightedQuestion : ''} ${
	  					question.source === 'CONVOTOP' 
						  ? styles.CONVOTOPBackground 
						  : question.source === 'SITE_LOCATION' 
						  ? styles.SITELOCATIONBackground 
						  : question.source === 'OWQQ' 
						  ? styles.OWQQBackground 
						  : ''
					  }`}
					  onClick={() => handleQuestionClick(question.quick_question, index)}
					>
					{question.quick_question}
				  </div>
				))}
		      </div>

			  <div className={styles.AigentarrowContainer} onClick={handleNext}>→</div>
			</div>

			<form onSubmit={handleSubmit} className={styles.AigentinputForm}>
			  <input
				type="text"
				className={styles.AigentinputField}
				value={userInput}
				onChange={(e) => setUserInput(e.target.value)}
				placeholder="Type your question..."
				onFocus={collapseCarousel}
			  />
			  {isLoading ? (
				<button onClick={cancelResponse} className={styles.AigentcancelButton}>✕</button>

			  ) : (
				<button type="submit" className={styles.AigentsubmitButton}>
				  ➤
				</button>
			  )}

			</form>
          </>
        )}
      </div>
    </div>
  );
};

export default Aigent_2;

