import React from 'react';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom';
//import React, { useState, useEffect } from 'react';
//import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import axios from 'axios';
import _ from 'lodash'; 

import { AuthProvider } from './contexts/AuthContext';
import { SiteAuthProvider } from './contexts/SiteAuthContext';
import { MortgageProvider } from './contexts/MortgageContext';
import { ChatProvider } from './contexts/ChatContext';
import { SiteCarouselProvider } from './contexts/SiteCarouselContext';
import { BinQGameProvider } from './contexts/BinQGameContext'; // Add BinQGameProvider
import { ImageBubbleGameProvider } from './contexts/ImageBubbleGameContext'; // Add BinQGameProvider
import { ListingDetailsProvider } from './contexts/ListingDetailsContext';
import { ListingAdminProvider } from './contexts/ListingAdminContext';

import QuestionGameContainer from './components/QuestionGameContainer';
import RenovationComponent from './components/renovationComponent';
import WelcomePage from './components/WelcomePage';
import MortgageCalculator from './components/MortgageCalculator';
import WrapperWithContactAndBot from './components/WrapperWithContactAndBot';
import WrapperWithCarouselAndBinImageQGame from './components/WrapperWithCarouselAndBinImageQGame';
import AigentWBinImageQGame from './components/Aigent_with_BinImageQGame';
import SummaryView from './components/SummaryView';
import WrapperMainSiteCarousel from './components/WrapperMainSiteCarousel';
import RenderImageBubbleGame from './components/RenderImageBubbleGame';
import WrapperWithAgentAndRenderNeighborhoodMap from './components/WrapperWithAgentAndRenderNeighborhoodMap';
import WrapperWithAgentSummary from './components/WrapperWithAgentSummary';
import LandingPage from './components/LandingPage'

import ListingAdmin from './components/ListingAdmin/ListingAdmin';
import ListingAdminMainPage from './components/ListingAdmin/ListingAdminMainPage';
import ListingAdminListing from './components/ListingAdmin/ListingAdminListing';
import ListingAdminAgent from './components/ListingAdmin/ListingAdminAgent';
import ListingAdminAssistant from './components/ListingAdmin/ListingAdminAssistant';
import ListingAdminCarousel from './components/ListingAdmin/ListingAdminCarousel'
import ListingAdminBinGame from './components/ListingAdmin/ListingAdminBinGame'
import ListingAdminImageBubbleGame from './components/ListingAdmin/ListingAdminImageBubbleGame'
import ListingAdminQuestions from './components/ListingAdmin/ListingAdminQuestions'
import ListingAdminConfirmListing from './components/ListingAdmin/ListingAdminConfirmListing'


import GeneralEntry from './components/GeneralEntry';
import AdminConsole from './components/AdminConsole';
import ShowListingIDEntry from './components/ShowListingIDEntry';

import SiteLoginSignUp from './components/SiteLoginSignUp';
import SiteLoginSignUpWrapper from './components/SiteLoginSignUpWrapper';



//import MainCarouselWrapper from './components/MainCarouselWrapper';
//import Aigent from './components/Aigent';
//import AigentWCarousel from './components/Aigent_with_carousel';
//import ImageCarousel from './components/imageCarousel';


import './styles/hbb_global.css';
import './App.css';
import './components/chartSetup';

// Importing QueryClient and QueryClientProvider from react-query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// Initialize Query Client
const queryClient = new QueryClient();

// Dynamically set the base URL based on the environment
const baseUrl = (() => {
  const hostname = window.location.hostname;
  if (hostname === 'localhost') {
    return 'http://localhost:5000/api';
  } else if (hostname === 'www.aigentTechnologies.com') {
    return 'https://www.aigentTechnologies.com/api';
  } else if (hostname === 'www.openhouseaigent.com') {
    return 'https://www.openhouseaigent.com/api';
  } else {
    return 'https://hbb-zzz.azurewebsites.net/api';
  }
})();


function App() {

	
	
  return (
    // Wrap the entire app with QueryClientProvider
    <QueryClientProvider client={queryClient}>
	  			 <ListingAdminProvider>

      <SiteCarouselProvider>
	    <ImageBubbleGameProvider>
		   <BinQGameProvider>
			 <ListingDetailsProvider>
			   <Router>
					<SiteAuthProvider>
				 <ChatProvider>
					<AuthProvider>
		 			  <MortgageProvider>
						<Routes>
						  <Route path="/" element={<LandingPage />} />
	  					  <Route path="/LandingPage" element={<LandingPage />} />
						  <Route path="/WelcomePage" element={<WelcomePage />} />
						  <Route path="/generalentry" element={<GeneralEntry />} />
						  <Route path="/admin-console" element={<AdminConsole />} />
						  <Route path="/ShowListingIDEntry" element={<ShowListingIDEntry />} />
						  <Route path="/siteloginsignup" element={<SiteLoginSignUp />} />
						  <Route path="/SiteLoginSignUpWrapper" element={<SiteLoginSignUpWrapper />} />
	  
						  <Route path="/MortgageCalculator" element={<MortgageCalculator />} />
						  <Route path="/AigentWBinImageQGame" element={<AigentWBinImageQGame />} />
						  <Route path="/RenovationComponent" element={<RenovationComponent />} />
						  <Route path="/SummaryView" element={<SummaryView />} />
						  <Route path="/WrapperWithCarouselAndBinImageQGame" element={<WrapperWithCarouselAndBinImageQGame />} />
						  <Route path="/WrapperWithAgentAndRenderNeighborhoodMap" element={<WrapperWithAgentAndRenderNeighborhoodMap />} />
						  <Route path="/WrapperWithContactAndBot" element={<WrapperWithContactAndBot />} />
						  <Route path="/WrapperMainSiteCarousel" element={<WrapperMainSiteCarousel />} />
						  <Route path="/WrapperWithAgentSummary" element={<WrapperWithAgentSummary />} />
						  <Route path="/QuestionGameContainer" element={<QuestionGameContainer />} />
						  
							<Route path="/ListingAdmin" element={<ListingAdmin />} />
							<Route path="/ListingAdminMainPage" element={<ListingAdminMainPage />} />
							<Route path="/ListingAdminListing" element={<ListingAdminListing />} />
							<Route path="/ListingAdminAgent" element={<ListingAdminAgent />} />
							<Route path="/ListingAdminAssistant" element={<ListingAdminAssistant />} />
							<Route path="/ListingAdminCarousel" element={<ListingAdminCarousel />} />
							<Route path="/ListingAdminBinGame" element={<ListingAdminBinGame />} />
							<Route path="/ListingAdminImageBubbleGame" element={<ListingAdminImageBubbleGame />} />
							<Route path="/ListingAdminQuestions" element={<ListingAdminQuestions />} />
							<Route path="/ListingAdminConfirmListing" element={<ListingAdminConfirmListing />} />

						  <Route path="*" element={<WelcomePage />} />

						</Routes>
					  </MortgageProvider>
					</AuthProvider>
				  </ChatProvider>
					</SiteAuthProvider>
				</Router>
			 </ListingDetailsProvider>
		   </BinQGameProvider>
		</ImageBubbleGameProvider>
      </SiteCarouselProvider>
			 </ListingAdminProvider>
    </QueryClientProvider>
  );
}

export default App;
